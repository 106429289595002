import { useCallback, useEffect, useRef, useState } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { MenuUnderlay, WrapperContainer } from './header.style';
import Head from './components/Head/head';
import Body from './components/Body/body';
import Footer from './components/Footer/footer';
import { useMenu } from './header.utils';
import HeaderMobile from './HeaderMobile/headerMobile';
import { MainContainer } from './HeaderMobile/headerMobile.style';
import { IHeaderProps } from './header.models';
import HeaderLite from './HeaderLite/HeaderLite';
import HeaderExtended from './HeaderExtended/headerExtended';
import TopHeaderBanner from './TopHeaderBanner/topHeaderBanner';
import { IBody, IHead } from '@vfit/shared/models';

const Header = ({
  lite,
  litePadding,
  header,
  onTrack,
  onTranslate,
  topHeaderBanner,
  disablePaddingTop = false,
  isExtendedHeader = false,
  extendedHeaderLogo = '',
}: IHeaderProps) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const { head, body, trovaNegozioPopup } = header || {};
  const { menuState: firstLevelState, setMenuState: setFirstLevelState } = useMenu();
  const { menuState: secondLevelState, setMenuState: setSecondLevelState } = useMenu();
  const { menuType, actionsMenu } = body || {};
  const headHeight = useRef(0);
  const bodyHeight = useRef(0);
  const { isDesktop, isTablet } = useDeviceType();

  const handlerHeadHeight = useCallback((height: number) => {
    headHeight.current = height;
  }, []);

  const handlerBodyHeight = useCallback((height: number) => {
    bodyHeight.current = height;
  }, []);

  useEffect(() => {
    if (isDesktop && !isTablet) {
      const haveTopBanner =
        header?.topBanner?.isEnabledForAllPages === 'true' || topHeaderBanner?.isEnabled === 'true';
      setHeaderHeight(headHeight.current + bodyHeight.current + (haveTopBanner ? 72 : 0));
    } else {
      setHeaderHeight(headHeight.current);
    }
  }, [headHeight, bodyHeight, headerHeight, isDesktop, isTablet]);

  useEffect(() => {
    if (litePadding) {
      const firstChild = document.getElementsByTagName('header');
      const firstSiblingElement = firstChild?.[0]?.nextElementSibling;
      if (firstSiblingElement)
        (firstSiblingElement as HTMLElement).style.cssText = `padding-top: ${
          isDesktop || isTablet ? '135px' : '95px'
        } !important`;
    }
  }, [litePadding, isDesktop, isTablet]);

  const getDesktopTopBanner = () => {
    if (header?.topBanner?.isEnabledForAllPages === 'true') {
      return <TopHeaderBanner {...header?.topBanner} />;
    }
    if (topHeaderBanner?.isEnabled === 'true') {
      if (topHeaderBanner?.useHeaderConfig === 'true') {
        return <TopHeaderBanner {...header?.topBanner} />;
      }
      return <TopHeaderBanner {...topHeaderBanner} />;
    }
    return null;
  };

  if (isExtendedHeader)
    return <HeaderExtended url={header?.body?.headerLogo?.url} logo={extendedHeaderLogo} />;
  if (lite || litePadding)
    return <HeaderLite isLite={!!lite} disablePaddingTop={disablePaddingTop} pt={headerHeight} onTranslate={onTranslate} />;

  return isDesktop && !isTablet ? (
    <WrapperContainer id="mainHeader" pt={headerHeight} disablePaddingTop={disablePaddingTop}>
      <nav>
        {getDesktopTopBanner()}
        <Head
          getContainerHeight={handlerHeadHeight}
          customerType={head?.customerType || []}
          otherInfo={head?.otherInfo || []}
          onTrack={onTrack}
          trovaNegozioPopup={trovaNegozioPopup}
        />
        <div
          onMouseLeave={() => {
            setFirstLevelState((prevData) => ({
              ...prevData,
              isHovered: false,
            }));
          }}
        >
          <Body
            getContainerHeight={handlerBodyHeight}
            menuType={menuType || []}
            actionsMenu={actionsMenu || []}
            openMenu={setFirstLevelState}
            isFirstLevelMenuOpen={firstLevelState}
            onTrack={onTrack}
            headerLogo={body?.headerLogo}
            onTranslate={onTranslate}
          />
          <Footer
            menuType={menuType || []}
            isFirstLevelMenuOpen={firstLevelState}
            isSecondLevelMenuOpen={secondLevelState}
            openSecondLevelMenu={setSecondLevelState}
            onTrack={onTrack}
          />
        </div>
      </nav>
      {firstLevelState.isHovered && <MenuUnderlay />}
    </WrapperContainer>
  ) : (
    <MainContainer id="mainHeader" pt={headerHeight}>
      <HeaderMobile
        header={{
          head: head as IHead,
          body: body as IBody,
          topBanner: header?.topBanner,
          getContainerHeight: handlerHeadHeight,
          trovaNegozioPopup
        }}
        topHeaderBanner={topHeaderBanner}
        onTrack={onTrack}
        onTranslate={onTranslate}
      />
    </MainContainer>
  );
};

export default Header;
