import { Children } from 'react';
import { Accordion } from '@vfit/shared/atoms';
import { AccordionNavigationType, INavigationMenu } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import {
  AccordionNavigationContainer,
  AccordionLinksContent,
  AccordionNavigationLink,
  AccordionNavigationDefaultLink,
  AccordionNavigationInnerContainer,
  DefaultLinkWrapper,
} from './accordionNavigation.style';
import { IAccordionNavigationProps } from './accordionNavigation.models';
import {
  EVENTS_TYPE as FOOTER_EVENTS_TYPE,
  ONCLICK_EVENTS as FOOTER_ONCLICK_EVENTS,
} from '../Footer/footer.utils';

const AccordionNavigation = ({
  links,
  containerStyle,
  config,
  handlerClickMenu,
  handlerTrackEvent,
  accordionNavigationType,
}: IAccordionNavigationProps) => {
  const router = useRouter();
  const {
    backgroundColor,
    textColor,
    padding,
    borderColor,
    fontFamily,
    fontSize,
    fontWeight,
    iconColor,
    iconSize,
    colorLinks,
    fontFamilyLinks,
    fontSizeLinks,
    fontWeightLinks,
    enableHighlight = false,
    highlightColor,
    fontSizeSecondLevel,
    fontFamilySecondLevel,
    fontWeightSecondLevel,
    lineHeight,
    lineHeightLiks,
    lineHeightSecondLevel,
    iconSizeSecondLevel,
    marginLeftLink,
    marginLeftSecondLevel,
  } = config || {};

  const renderAccordion = (
    title: string,
    content: JSX.Element,
    enableBorder?: boolean,
    isSecondLevel?: boolean
  ) => (
    <Accordion
      accordionNavigationType={accordionNavigationType}
      background={backgroundColor}
      borderColor={enableBorder ? borderColor : 'transparent'}
      padding={padding}
      textColor={textColor}
      fontFamily={
        accordionNavigationType === AccordionNavigationType.MENU && isSecondLevel
          ? fontFamilySecondLevel
          : fontFamily
      }
      fontSize={
        accordionNavigationType === AccordionNavigationType.MENU && isSecondLevel
          ? fontSizeSecondLevel
          : fontSize
      }
      lineHeight={
        accordionNavigationType === AccordionNavigationType.MENU && isSecondLevel
          ? lineHeightSecondLevel
          : lineHeight
      }
      fontWeight={
        accordionNavigationType === AccordionNavigationType.MENU && isSecondLevel
          ? fontWeightSecondLevel
          : fontWeight
      }
      title={title}
      content={[content]}
      iconColor={iconColor}
      iconSize={
        accordionNavigationType === AccordionNavigationType.MENU && isSecondLevel
          ? iconSizeSecondLevel
          : iconSize
      }
      enableHighlight={enableHighlight}
      highlightColor={highlightColor}
    />
  );

  const accordionLinks = (items: INavigationMenu[], fromFirstLevel?: boolean): JSX.Element => (
    <AccordionLinksContent>
      {Children.toArray(
        items.map((l) => {
          if (!l.links || l.links.length === 0) {
            if (fromFirstLevel) {
              return (
                <AccordionNavigationLink
                  target="_blank"
                  rel="noreferrer"
                  href={l.url}
                  fontFamily={
                    accordionNavigationType === AccordionNavigationType.MENU
                      ? fontFamilyLinks
                      : fontFamily
                  }
                  fontSize={
                    accordionNavigationType === AccordionNavigationType.MENU
                      ? fontSizeLinks
                      : fontSize
                  }
                  fontWeight={
                    accordionNavigationType === AccordionNavigationType.MENU
                      ? fontWeightLinks
                      : fontWeight
                  }
                  background={backgroundColor}
                  color={textColor}
                  enableMargin={fromFirstLevel}
                  leftMargin={marginLeftLink}
                  lineHeight={
                    accordionNavigationType === AccordionNavigationType.MENU
                      ? lineHeightLiks
                      : lineHeight
                  }
                >
                  {l.category}
                </AccordionNavigationLink>
              );
            }
            return (
              <AccordionNavigationDefaultLink
                onClick={(evt) => {
                  if (handlerClickMenu) {
                    handlerClickMenu(
                      evt,
                      l,
                      // {
                      //   track: undefined,
                      //   name: tagName || `${onTrack}_MDD`,
                      // },
                      router
                    );
                  } else if (handlerTrackEvent) {
                    handlerTrackEvent(l.category);
                  }
                  if (l.url && FOOTER_EVENTS_TYPE.includes(l.url)) {
                    evt.preventDefault();
                    FOOTER_ONCLICK_EVENTS[l.url]();
                  }
                }}
                colorLinks={colorLinks}
                fontFamilyLinks={fontFamilyLinks}
                fontSizeLinks={fontSizeLinks}
                fontWeightLinks={fontWeightLinks}
                lineHeight={
                  accordionNavigationType === AccordionNavigationType.MENU
                    ? lineHeightLiks
                    : lineHeight
                }
                target="_blank"
                rel="noreferrer"
                href={l.url}
                enableMargin
                leftMargin={marginLeftLink}
              >
                {l.category}
              </AccordionNavigationDefaultLink>
            );
          }
          return (
            <AccordionNavigationContainer
              enableMargin
              marginLeft={marginLeftSecondLevel}
              style={{ ...containerStyle }}
            >
              {renderAccordion(
                l.category,
                accordionLinks(l.links?.filter((f) => !!f?.url) || [], false),
                false,
                true
              )}
            </AccordionNavigationContainer>
          );
        })
      )}
    </AccordionLinksContent>
  );

  return (
    <AccordionNavigationContainer style={{ ...containerStyle }}>
      {Children.toArray(
        links?.map((l) => {
          if (l.links) {
            return (
              <AccordionNavigationInnerContainer
                accordionNavigationType={accordionNavigationType}
                className="accordionNavgationInnerContainer"
              >
                {renderAccordion(
                  l.category,
                  accordionLinks(l.links?.filter((f) => !!f?.url) || [], false)
                )}
              </AccordionNavigationInnerContainer>
            );
          }
          return (
            <DefaultLinkWrapper
              accordionNavigationType={accordionNavigationType}
              className="defaultLinkWrapper"
            >
              <AccordionNavigationDefaultLink
                onClick={(evt) => {
                  if (handlerClickMenu) handlerClickMenu(evt, l, router);
                }}
                target="_blank"
                rel="noreferrer"
                href={l.url}
                colorLinks="#0d0d0d"
                fontFamilyLinks={fontFamily}
                fontSizeLinks={fontSize}
              >
                {l.category}
              </AccordionNavigationDefaultLink>
            </DefaultLinkWrapper>
          );
        })
      )}
    </AccordionNavigationContainer>
  );
};

export default AccordionNavigation;
