import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  padding: 16px 0;
  padding-left: 16px;
  padding-right: 19px;
  height: 63px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}) {
    height: 56px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 30px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 32px;
    height: 32px;
  }

  a {
    display: flex;
    height: 100%;
    width: 100%;
  }

  svg {
    width: 32px;
    height: auto;
  }
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  border: 0;
  width: 24px;
  height: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 32px;
    height: 32px;
  }

  svg {
    width: 24px;
    height: auto;

    @media (min-width: ${breakpoints.mobile}) {
      width: 19px;
    }

    @media (min-width: ${breakpoints.tablet}) {
      width: 25px;
    }
  }
`;

export const ActionsMenuList = styled.li`
  list-style: none;
  width: 24px;
  height: 24px;
  margin: 0 16px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 20px;
    height: 23px;
  }

  a {
    justify-content: center;
  }

  a svg {
    width: 15px;

    @media (min-width: ${breakpoints.tablet}) {
      width: 100%;
    }
  }

  a:link {
    display: flex;
    height: 100%;
  }

  a:visited {
    fill: ${colors.$0d0d0d};
  }

  a:hover svg path.stroke {
    stroke: ${colors.$e60000};
  }

  a:hover svg path.fill,
  a:active {
    fill: ${colors.$e60000};
  }
`;

export const ActionsMenu = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  @media (min-width: ${breakpoints.desktop}) {
    gap: 25px;
  }

  .searchbox-icon {
    svg {
      width: 19px;

      @media (min-width: ${breakpoints.tablet}) {
        width: 25px;
      }

      @media (min-width: ${breakpoints.desktop}) {
        width: 100%;
      }
    }
  }
`;
