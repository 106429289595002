import React, { useState } from 'react';
import { Marker } from '@vfit/shared-icons';
import { IHead } from '@vfit/shared/models';
import { ImageAtoms } from '@vfit/shared/atoms';
import {
  IconButtonsWrapper,
  IconMarkerWrapper,
  LeftButton,
  MainContainer,
  OtherActionsContainer,
  RightButton,
} from './footer.style';
import TrovaModal from '../../../components/TrovaPopUp/modal';

const Footer: React.FC<IHead> = ({ otherInfo, trovaNegozioPopup }) => {
  const [isTrovaModal, setIsTrovaModal] = useState(false);
  const handlerClick = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <MainContainer>
      <OtherActionsContainer>
        {otherInfo && otherInfo?.[0]?.url && (
          <LeftButton onClick={() => handlerClick(otherInfo[0].url)}>
            <IconButtonsWrapper>
              <Marker />
            </IconButtonsWrapper>
            <p>{otherInfo[0].info}</p>
          </LeftButton>
        )}
        {otherInfo?.[1]?.url && (
          <RightButton onClick={() => handlerClick(otherInfo?.[1]?.url || '')}>
            <IconMarkerWrapper>
              <ImageAtoms nameCard="footerIcon" image={otherInfo?.[1]?.logo?.image} />
            </IconMarkerWrapper>
            <p>{otherInfo[1].info || ''}</p>
          </RightButton>
        )}
        {otherInfo?.[0] && trovaNegozioPopup && (
          <RightButton onClick={() => setIsTrovaModal(true)}>
            <IconMarkerWrapper>
              <ImageAtoms nameCard="footerIcon" image={otherInfo?.[0]?.logo?.image} />
            </IconMarkerWrapper>
            <p> {otherInfo[0]?.info || ''}</p>
          </RightButton>
        )}
        <TrovaModal
          show={isTrovaModal}
          data={trovaNegozioPopup ?? {}}
          onCloseModal={() => setIsTrovaModal((prevState) => !prevState)}
        />
      </OtherActionsContainer>
    </MainContainer>
  );
};
export default Footer;
