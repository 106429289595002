const switchLang = (
  allLangs: { id: string; value: string; isSelected?: boolean }[],
  id: string
) => {
  const orgLangs = allLangs?.filter((el) => el.id !== id);
  const foundedLang = allLangs.find((el) => el.id === id);
  if (foundedLang) orgLangs.unshift(foundedLang);
  return orgLangs;
};

export { switchLang };
