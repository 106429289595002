import { IHeaderData, IPageNavigation } from '@vfit/shared/models';

const organizeHeader = (header: IHeaderData): IHeaderData => ({
  head: header?.head,
  body: header?.body,
  topBanner: header?.topBanner,
  trovaNegozioPopup: header?.trovaNegozioPopup,
});

export const useHeader = (cmsJson?: IPageNavigation | undefined) => {
  if (!cmsJson)
    return {
      header: undefined,
      tagging: undefined,
    };
  const header = organizeHeader(cmsJson?.header);
  const tagging = cmsJson?.tagging?.parameter;
  return { header, tagging };
};
