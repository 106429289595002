import React, { useState, useEffect } from 'react';
import { IHeaderTopBanner } from '@vfit/shared/models';
import { Button, ImageAtoms, Svg } from '@vfit/shared/atoms';
import { Colors } from '@vfit/shared/themes';
import { LogoContainer, MainContainer } from './topHeaderBanner.style';

const TopHeaderBanner: React.FC<IHeaderTopBanner> = ({
  textColor,
  logo,
  background,
  afterLogoLabel,
  beforeLogoLabel,
  actionLabel,
  actionVariant,
  actionUrl,
  isBlank,
}) => {
  const TOP_HEIGHT = 72;
  const [height, setHeight] = useState(TOP_HEIGHT);
  const [opacity, setOpacity] = useState(1);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const newHeight = TOP_HEIGHT - scrollPosition / 2;
    const newOpacity = 1 - scrollPosition / 200;
    setHeight(Math.max(newHeight, 0));
    setOpacity(Math.max(newOpacity, 0));
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <MainContainer
      headerHeight={TOP_HEIGHT}
      color={textColor}
      onClick={() => {
        if (actionUrl) window.open(actionUrl, isBlank === 'true' ? '_blank' : '_self');
      }}
      style={{
        ...(background && { background }),
        ...(actionUrl && { cursor: 'pointer' }),
        height: `${height}px`,
        transition: 'height 0.3s ease',
      }}
    >
      <div style={{ opacity, display: 'flex', transition: 'opacity 0.3s ease' }}>
        <div className="header-top-banner-first-section">
          {beforeLogoLabel && <span>{beforeLogoLabel}</span>}
          {logo && (
            <LogoContainer>
              <ImageAtoms nameCard="topBannerLogo" image={logo} />
            </LogoContainer>
          )}
        </div>
        <div className="header-top-banner-second-section">
          {afterLogoLabel && (
            <div className="after-logo-button-section">
              <span>{afterLogoLabel}</span>
            </div>
          )}
          {actionUrl && actionLabel && (
            <div className="button-section">
              <Button
                variant={actionVariant}
                onClick={() => {
                  window.open(actionUrl, isBlank === 'true' ? '_blank' : '_self');
                }}
              >
                <span>{actionLabel}</span>
                <Svg name="arrowRight" width={24} color={textColor as Colors} />
              </Button>
            </div>
          )}
          {actionUrl && !actionLabel && (
            <div
              className="button-section"
              style={{
                cursor: 'pointer',
              }}
            >
              <Svg name="arrowRightFw" width={21} color={textColor as Colors} />
            </div>
          )}
        </div>
      </div>
    </MainContainer>
  );
};
export default TopHeaderBanner;
