import { colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

// ModalWrapper - Outer container
export const ModalWrapper = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  
  a {
    padding: clamp(10px, 2vw, 12px) clamp(30px, 5vw, 60px);
    font-size: clamp(12px, 2.5vw, 16px);
    background-color: #e30613;
    color: ${colors.$ffffff}; /* Regular link color */
    border: none;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
    color: ${colors.$ffffff} !important;
    font-weight: 700 !important;

    :visited {
      color: ${colors.$ffffff} !important;
    }
  }
  @media (max-height: 700px) {
    padding: 20px;
  }
  @media (max-height: 500px) {
    padding: 80px 10px 20px 10px a {
      font-size: 14px;
      padding: clamp(10px, 2vw, 5px) clamp(30px, 5vw, 20px);
    }
  }

  @media (max-height: 500px) {
    font-size: 14px;
    padding: clamp(10px, 2vw, 5px) clamp(30px, 5vw, 20px);
  }

  @media (max-width: 480px) {
    a {
      padding: 10px 30px;
    }
  }

  
`;

// ModalHeader - Scales dynamically
export const ModalHeader = styled.div`
  font-size: 36px; /* Dynamically scale font size */
  font-family: ${fonts.exbold};
  margin-bottom: 15px;
  text-align: left; /* Align center for better presentation */
  line-height: 1.2; /* Improve multi-line spacing */

  @media (max-height: 700px) {
    font-size: 26px;
  }
  @media (max-width: 800px) {
    font-size: 26px;
  }
`;

export const Link = styled.a`
  padding: clamp(10px, 2vw, 12px) clamp(30px, 5vw, 60px);
  font-size: clamp(12px, 2.5vw, 16px);
  background-color: #e30613;
  color: ${colors.$ffffff};  /* Regular link color */
  border: none;
  border-radius: 25px;
  cursor: pointer;
  text-decoration: none;

  /* Specific targeting for the modal link */
  &.modal-link {
    color: ${colors.$ffffff}; !important; /* Ensure link color is white */
  }

  &:visited {
    color: ${colors.$ffffff}; !important /* Ensure visited link stays white */
  }

  @media (max-height: 500px) {
    font-size: 14px;
    padding: clamp(10px, 2vw, 5px) clamp(30px, 5vw, 20px);
  }

  @media (max-width: 480px) {
    padding: 10px 30px;
  }
`;

// Title - Adjusts based on screen size
export const Title = styled.div`
  font-size: clamp(14px, 3vw, 18px); /* Scales dynamically */
  line-height: 1.5;
  color: #000;
  margin: 10px 0px 5px;
  font-family: ${fonts.regular};
  text-align: left;
  @media (max-height: 500px) {
    font-size: 18px; /* Smaller font size on very small screens */
  }
`;

// ButtonContainer - Center buttons
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 480px) {
    margin-top: 15px;
  }
`;

// BodyContainer - Handles content padding
export const BodyContainer = styled.div`
  max-width: clamp(90%, 80vw, 95%);
  @media (max-width: 768px) {
    padding: 15px;
    max-width: 90%;
  }

  @media (max-width: 480px) {
  padding: 10px; 
    max-width: 95%;
  }
`;

