import { breakpoints, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const MainContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${pxToRem(72)};
  border-bottom: 1px solid #cccccc80;
`;

export const LogoWrapper = styled.div<{ haveCustomLogo?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 80px;
  img {
    width: auto !important;
    min-height: 32px !important;
    max-height: 45px !important;
  }
  @media (max-width: ${breakpoints.desktop}) {
    padding-left: 16px;
    img {
      width: auto !important;
      min-height: 32px !important;
      max-height: 45px !important;
    }
  }
`;
