import { Logo } from '@vfit/shared-icons';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  WrapperHeaderLite,
  MainContainerLite,
  LogoWrapperLite,
  WrapperContainerLite,
  MainContainer,
  LogoWrapper,
} from './HeaderLite.style';
import { IHeaderModel } from './HeaderLite.models';
import React from 'react';
import dynamic from 'next/dynamic';

const LanguageSelector = dynamic(()=> import('@vfit/shared/components').then((e)=>e.LanguageSelector),{ssr:false})

const HeaderLite = ({ isLite, pt = 0, disablePaddingTop = false, isMva = false, onTranslate }: IHeaderModel) => {
  const { isMobile, isTablet } = useDeviceType();

  if (isMva) {
    return (
      <MainContainer id="header-lite">
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <LanguageSelector onSelectLanguage={(idLang) => onTranslate?.(idLang)} isMva />
      </MainContainer>
    );
  }

  return (
    <WrapperContainerLite
      disablePaddingTop={disablePaddingTop}
      pt={pt}
      className="headerLite"
      id="header-lite"
    >
      <WrapperHeaderLite>
        <MainContainerLite isLite={isLite}>
          <LogoWrapperLite isMobile={isMobile} isTablet={isTablet}>
            <Logo />
          </LogoWrapperLite>
        </MainContainerLite>
      </WrapperHeaderLite>
    </WrapperContainerLite>
  );
};

export default HeaderLite;
