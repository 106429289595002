import { ImageAtoms } from '@vfit/shared/atoms';
import { LogoWrapper, MainContainer } from './headerExtended.style';
import { IHeaderExtendedProps } from './headerExtended.models';
import { Wordmark } from '@vfit/shared-icons';

const HeaderExtended = ({ url, logo }: IHeaderExtendedProps) => (
  <MainContainer id="extendedHeader">
    <LogoWrapper haveCustomLogo={!!logo}>
      <a href={url || '/'}>
        {logo && <ImageAtoms image={logo} nameCard="logo-image" />}
        {!logo && <Wordmark />}
      </a>
    </LogoWrapper>
  </MainContainer>
);

export default HeaderExtended;
