import { IBody, IconsAction } from '@vfit/shared/models';
import { Bell, Logo, Search, User } from '@vfit/shared-icons';
import React, { Children, useLayoutEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { handlerClickMenu } from '../../header.utils';
import {
  ActionsMenu,
  ActionsMenuList,
  LogoWrapper,
  MainContainer,
  Menu,
  MenuList,
} from './body.style';
import SearchBox from '../SearchBox/SearchBox';

const icons: IconsAction = {
  search: <Search />,
  remainder: <Bell />,
  profile: <User />,
};
const LanguageSelector = dynamic(()=> import('@vfit/shared/components').then((e)=>e.LanguageSelector),{ssr:false})

const Body: React.FC<IBody> = ({
  actionsMenu,
  menuType,
  openMenu,
  isFirstLevelMenuOpen,
  getContainerHeight,
  headerLogo,
  onTranslate
}) => {
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const OVERLOPPED_ITEM_ID = 'menu-list';
  const isEnabledMultiLang = process.env['NEXT_PUBLIC_ENABLE_MULTILANGUAGE'] === 'TRUE';

  useLayoutEffect(() => {
    if (containerRef && containerRef.current && getContainerHeight) {
      getContainerHeight(containerRef.current.clientHeight);
    }
  }, [getContainerHeight]);

  return (
    <MainContainer ref={containerRef}>
      <a href={headerLogo?.url}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
      </a>
      <Menu id={OVERLOPPED_ITEM_ID}>
        {Children.toArray(
          menuType?.map((menu, menuIndex) => (
            <MenuList>
              <a
                onClick={(evt) => handlerClickMenu(evt, menu)}
                className={
                  isFirstLevelMenuOpen?.isHovered && isFirstLevelMenuOpen?.menuIndex === menuIndex
                    ? 'showLine'
                    : ''
                }
                onMouseEnter={() => {
                  if (openMenu) {
                    openMenu({
                      menuIndex,
                      isHovered: true,
                    });
                  }
                }}
                href={menu.url || '/'}
              >
                {menu.category}
              </a>
            </MenuList>
          ))
        )}
      </Menu>
      <ActionsMenu isEnabledMultiLang={isEnabledMultiLang}>
        <ActionsMenuList>
          <SearchBox overlopperItemId={OVERLOPPED_ITEM_ID} />
        </ActionsMenuList>
        {Children.toArray(
          actionsMenu?.map((action) => {
            if (!action.url || !icons[action?.category || '']) return null;
            return (
              <ActionsMenuList>
                <a href={action.url} aria-label={action.category} style={{ display: 'block' }}>
                  {icons[action.category]}
                </a>
              </ActionsMenuList>
            );
          })
        )}
      </ActionsMenu>
      <LanguageSelector onSelectLanguage={(idLang) => onTranslate?.(idLang)} />
    </MainContainer>
  );
};

export default Body;
