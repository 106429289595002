import React, { Children } from 'react';
import { fonts, pxToFontSize } from '@vfit/shared/themes';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  AccordionNavigationType,
  IAccordionNavigationConfig,
  IFooterLogo,
  INavigationMenu,
} from '@vfit/shared/models';
import { ImageAtoms } from '@vfit/shared/atoms';
import {
  FooterTopContainer,
  FooterBottomContainer,
  FooterBottomContent,
  FooterLinks,
  FooterLinksCol,
  FooterContainer,
  FooterCopyright,
  FooterMobileLinks,
  FooterMobileLink,
  FooterButton,
  FooterAppWrapper,
  LogoContainer,
  TopFooterBannerBottomContent,
  FooterTopBannerLinks,
} from './footer.style';
import AccordionNavigation from '../AccordionNavigation/accordionNavigation';
import { IFooterProps } from './footer.models';
import { EVENTS_TYPE, ONCLICK_EVENTS } from './footer.utils';

const Footer = ({ footer, subChildren }: IFooterProps) => {
  const { isDesktop, isMobile } = useDeviceType();
  const { app, footerTopBanner, socials, footerLinks, copyright, mobileLinks } = footer;
  const accordionNavigationConfig: IAccordionNavigationConfig = {
    backgroundColor: '#0d0d0d',
    borderColor: '#fff',
    textColor: '#fff',
    fontFamily: fonts.regular,
    fontSize: pxToFontSize(20),
    fontWeight: 700,
    iconColor: '#fff',
    fontSizeLinks: pxToFontSize(16),
    fontWeightLinks: 400,
    fontFamilyLinks: fonts.regular,
    colorLinks: '#fff',
    highlightColor: '#e60000',
    enableHighlight: true,
  };

  const handleRedirect = (url?: string) => () => {
    if (url) window.open(url, '_blank');
  };

  const linksMobile = () =>
    footerLinks && (
      <AccordionNavigation
        links={footerLinks}
        config={accordionNavigationConfig}
        // handlerTrackEvent={handlerTrackEvent}
        accordionNavigationType={AccordionNavigationType.FOOTER}
      />
    );

  const topFooterBanner = () => {
    const {
      isEnabled: isEnabledFooterTopBanner,
      logo: logoFooterTopBanner,
      background: backgroundTopFooterBanner,
      textColor: textColorFooterBanner,
      beforeLogoLabel: beforeLogoLabelFooterTopBanner,
      firstLinkLabel: firstLinkLabelFooterTopBanner,
      firstLinkUrl: firstLinkUrlFooterTopBanner,
      secondLinkLabel: secondLinkLabelFooterTopBanner,
      secondLinkUrl: secondLinkUrlFooterTopBanner,
      thirdLinkLabel: thirdLinkLabelFooterTopBanner,
      thirdLinkUrl: thirdLinkUrlFooterTopBanner,
    } = footerTopBanner || {};

    const isValidURL = (url: string) => {
      const pattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z0-9]{2,}(\/[^\s]*)?$/i;
      return pattern.test(url);
    };

    if (isEnabledFooterTopBanner != 'true') return null;

    const beforeLogoTextIsUrl =
      beforeLogoLabelFooterTopBanner && isValidURL(beforeLogoLabelFooterTopBanner);

    return (
      <TopFooterBannerBottomContent
        color={textColorFooterBanner}
        style={{
          ...(backgroundTopFooterBanner && { background: backgroundTopFooterBanner }),
        }}
      >
        <FooterTopBannerLinks>
          <FooterTopBannerLinks
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {beforeLogoLabelFooterTopBanner && !beforeLogoTextIsUrl && (
              <p>{beforeLogoLabelFooterTopBanner}</p>
            )}
            {logoFooterTopBanner && (
              <LogoContainer
                onClick={() => {
                  if (beforeLogoTextIsUrl) window.open(beforeLogoLabelFooterTopBanner, '_blank');
                }}
                style={{
                  marginLeft: beforeLogoLabelFooterTopBanner ? 16 : 0,
                  ...(beforeLogoTextIsUrl && { cursor: 'pointer' }),
                }}
              >
                <ImageAtoms nameCard="topFooterBannerLogo" image={logoFooterTopBanner} />
              </LogoContainer>
            )}
          </FooterTopBannerLinks>
          {firstLinkLabelFooterTopBanner && firstLinkUrlFooterTopBanner && (
            <FooterTopBannerLinks>
              <a target="_blank" rel="noreferrer" href={firstLinkUrlFooterTopBanner}>
                {firstLinkLabelFooterTopBanner}
              </a>
            </FooterTopBannerLinks>
          )}
          {secondLinkLabelFooterTopBanner && secondLinkUrlFooterTopBanner && (
            <FooterTopBannerLinks>
              <a target="_blank" rel="noreferrer" href={secondLinkUrlFooterTopBanner}>
                {secondLinkLabelFooterTopBanner}
              </a>
            </FooterTopBannerLinks>
          )}
          {thirdLinkLabelFooterTopBanner && thirdLinkUrlFooterTopBanner && (
            <FooterTopBannerLinks>
              <a target="_blank" rel="noreferrer" href={thirdLinkUrlFooterTopBanner}>
                {thirdLinkLabelFooterTopBanner}
              </a>
            </FooterTopBannerLinks>
          )}
        </FooterTopBannerLinks>
      </TopFooterBannerBottomContent>
    );
  };

  const footerSocials = () => {
    const { alt: socialAltImg, image: imageSocial } = socials?.logo || { alt: '', image: '' };
    return (
      socials?.title && (
        <FooterBottomContent>
          <div>
            <p>{socials.title}</p>
            <div>
              {Children.toArray(
                socials.elements?.map((element: IFooterLogo, _id) => {
                  const { image, alt, url } = element;
                  return (
                    <FooterButton marginRight={14} type="button" onClick={handleRedirect(url)}>
                      <ImageAtoms
                        nameCard={`socialIcons-${_id}`}
                        alt={alt || ''}
                        sizes="30px"
                        width="30px"
                        image={image}
                      />
                    </FooterButton>
                  );
                })
              )}
            </div>
          </div>
          {socials.logo && (
            <div>
              <FooterButton type="button" onClick={handleRedirect(socials.logo?.url)}>
                <ImageAtoms
                  nameCard="socialsLogo"
                  alt={socialAltImg || ''}
                  sizes="48px"
                  width="48px"
                  image={imageSocial}
                />
              </FooterButton>
            </div>
          )}
        </FooterBottomContent>
      )
    );
  };

  const footerApp = () =>
    app?.message && (
      <FooterTopContainer>
        <div>
          <p>{app.message}</p>
        </div>
        <FooterAppWrapper height={51}>
          {Children.toArray(
            app.logos?.map((logo: IFooterLogo, indexLogo: number) => {
              const { image, alt, url } = logo;
              return (
                <FooterButton
                  marginRight={indexLogo !== app!.logos!.length - 1 ? 30 : 0}
                  type="button"
                  onClick={handleRedirect(url)}
                >
                  <ImageAtoms
                    nameCard="apps"
                    alt={alt || ''}
                    sizes="146px, (max-width: 375px) and (min-width: 290px) 119px"
                    image={image}
                  />
                </FooterButton>
              );
            })
          )}
        </FooterAppWrapper>
      </FooterTopContainer>
    );

  const renderMobileLinks = () => (
    <FooterMobileLinks>
      {Children.toArray(
        mobileLinks?.map((mLink: INavigationMenu, indexLink: number) => (
          <FooterMobileLink
            onClick={() => {
              window.open(mLink.url || '', '_blank');
            }}
            borderLeft={indexLink > 0}
          >
            <span>{mLink.category}</span>
          </FooterMobileLink>
        ))
      )}
    </FooterMobileLinks>
  );

  const links = () =>
    footerLinks && (
      <FooterLinks>
        {Children.toArray(
          footerLinks?.map((f: INavigationMenu) => (
            <FooterLinksCol>
              <p>{f.category}</p>
              <div>
                {Children.toArray(
                  f.links
                    ?.filter((l) => !!l?.url)
                    ?.map((item: INavigationMenu) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        onClick={(evt) => {
                          const { url } = item;
                          // handlerTrackEvent(item.category);
                          if (url && EVENTS_TYPE.includes(url)) {
                            evt.preventDefault();
                            ONCLICK_EVENTS[url]();
                          }
                        }}
                        target="_blank"
                        rel="noreferrer"
                        href={item.url}
                      >
                        {item.category}
                      </a>
                    ))
                )}
              </div>
            </FooterLinksCol>
          ))
        )}
      </FooterLinks>
    );

  return (
    <FooterContainer>
      {footerApp()}
      {subChildren || null}
      {topFooterBanner()}
      <FooterBottomContainer>
        {footerSocials()}
        {isDesktop ? links() : linksMobile()}
        {copyright && (
          <FooterCopyright>
            <span>{copyright}</span>
          </FooterCopyright>
        )}
        {isMobile && mobileLinks && renderMobileLinks()}
      </FooterBottomContainer>
    </FooterContainer>
  );
};

export default Footer;
